
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      ph: {
        type: String,
        title: "Food Is Medicine",
        logo: "brand/FARMacy_Seal.png",
        img: "images/salad.jpg",
        imgAlt: "Salad on Table",
        bgColor: "var(--green)",
        bgImg: "images/salad.jpg",
        bgAlt: "bg Alt Tag",

        sub: "",
      },
    };
  },
});
